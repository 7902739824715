import React from 'react';
import Layout from "../components/layout";
import Jumbotron from 'react-bootstrap/Jumbotron';

const activateAccount = () => {

        const queryString = global.window?.location.search
        const urlParams = new URLSearchParams(queryString)
        let authid = urlParams.get("AuthId");

        if(authid){    
        const opts = {
            "Id": authid
        }

        fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/sign-up', {
        method: 'PUT',
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(opts)
        }).then(function(response) {
        return response.json();
        }).then(function(data) {
           
        if(data.success) {
            let title = '<b style="color:green;">Success ... !!</b>';
            let message = '<p>You email has been verified. Please <a href="/app/login">login</a> to continue.</p>';
            document.getElementById("heading").innerHTML = title;
            document.getElementById("message").innerHTML = message;
        }
        else if(data.errorCode === 500){
            let title = '<b style="color:red;">Invalid Link ... !!</b>';
            let message = 'link expired or invalid';
            document.getElementById("heading").innerHTML = title;
            document.getElementById("message").innerHTML = message;
        } else {
            let title = '<b style="color:red;">Already Verified ... !!</b>';
            let message = 'your account already verified !';
            document.getElementById("heading").innerHTML = title;
            document.getElementById("message").innerHTML = message;
        }

       });
    }
    

    return (
        <Layout>
            <Jumbotron className="bg-lightblue">
            <div className="container">
                <div style={{maxWidth:"600px",margin:"auto"}}>
                <h2 id="heading"><b>Please Wait ...</b></h2>
                <div className="mt-4" id="message">
                 Your account will be activated in sometime.
                </div>
                </div>
            </div>
            </Jumbotron>
        </Layout>
    );
};

export default activateAccount;